//import logo from './logo.svg';
import './Home.css';

function Home() {
    return (
        <div className="landing-container">
            <header className="header">
                <h1>Welcome to AhaSum</h1>
                <p className="tagline">Expertise Meets Innovation: Engineering Tomorrow’s Solutions</p>
            </header>

            <section className="about-section">
                <h2>What is AhaSum?</h2>
                <p>
                    AhaSum is where a team of skilled engineers with an entrepreneurial spirit come 
                    together to explore, innovate, and build solutions with real-world impact. 
                    We are not just creators; we are problem solvers with a startup mindset, 
                    transforming ideas into products and technologies that make a difference.
                </p>
            </section>

            <section className="what-we-do-section">
                <h2>What We Do Now?</h2>
                <ul>
                    <li><strong>Learn & Grow:</strong> Share knowledge, discover new technologies, and refine your skills with like-minded engineers.</li>
                    <li><strong>Build & Innovate:</strong> Got an idea? AhaSum is the place to turn concepts into real-world products.</li>
                    <li><strong>Collaborate & Research:</strong> Work together to develop solutions that solve real-world problems.</li>
                </ul>
            </section>

            <section className="vision-section">
                <h2>A Space for Every Vision</h2>
                <p>
                    AhaSum is in the exploratory phase, and its potential is limitless. Whether it evolves into 
                    a company, forum, or something entirely new, our mission remains the same: to empower 
                    engineering to push the boundaries of what's possible.
                </p>
            </section>
        </div>
    );
};

export default Home;
