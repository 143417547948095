import './Labs.css';

function Labs() {
    return (
        <div className="landing-container">
            <header className="header">
                <h1>AhaSum - Labs</h1>
                <p className="tagline">Research and Expriments page of Ahasum</p>
            </header>
        </div>
    );
};

export default Labs;
