import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeSite from './home/Home'; // Component for ahasum.com
import LabsRoutes from './labs/LabsRoutes';

function App() {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0]; // Extract the subdomain

  return (
    <Router>
      <Routes>
        <Route path="/*" element={subdomain === 'labs' ? <LabsRoutes /> : <HomeSite />} />
      </Routes>
    </Router>
  );
}

export default App;
