import React, { useState, useEffect } from 'react';

function InstagramDashboard() {
    const [nonFollowers, setNonFollowers] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
    
        if (!token) {
            console.log("token null");
            // Redirect to the backend for Instagram OAuth authentication
            window.location.href = '/labs/api/v1/instagram/auth';
        } else {
            console.log("token valid")
            fetchNonFollowers(token);
        }
    }, []);
    

    const fetchNonFollowers = (token) => {
        fetch(`/labs/api/v1/instagram/dashboard?token=${token}`)
            .then(response => {
                if (!response.ok) {
                    // Token might be invalid or expired, reauthenticate
                    console.log("response not ok");
                    //window.location.href = '/labs/api/v1/instagram/auth';
                }
                console.log("responseok");
                console.log(response.json())
                return response.json();
            })
            .then(data => setNonFollowers(data.nonFollowers))
            .catch(error => console.error("Error fetching non-followers:", error));
    };
    

    return (
        <div>
            <h1>Instagram Non-Followers</h1>
            <ul>
                {nonFollowers.map(user => (
                    <li key={user}>{user}</li>
                ))}
            </ul>
        </div>
    );
}

export default InstagramDashboard;
